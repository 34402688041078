import React from "react";
import Section from "../../Components/Section";

const openingPositionsList = [
    // {
    //     position: "Full Stack Developer",
    //     type: "Intern",
    //     location: "Hybrid/Remote",
    //     period: "6 Months",
    //     linkedinLink: "",
    // },
];

const OpeningPositions = () => {

    const renderOpenPositions = () => {
        return openingPositionsList.map(({position, type, location, period, linkedinLink}, index) => {
            return (
                <div key={index} className="col p-1">
                    <div className="border border-secondary border-1 border-opacity-75 rounded p-3">
                        <h5 className="fw-bolder text-primary m-1">{position}</h5>
                        <div className="m-0 my-3 p-0">
                            <small className="bg-light rounded text-black py-1 px-2 mx-1 my-auto">
                                {type}
                            </small>
                            <small className="bg-light rounded text-black py-1 px-2 mx-1 my-auto">
                                {location}
                            </small>
                            <small className="bg-light rounded text-black py-1 px-2 mx-1 my-auto">
                                {period}
                            </small>
                        </div>
                        <hr/>
                        <div className="my-auto">
                            <a href={linkedinLink} className="btn btn-primary" target="_blank"
                               rel="noreferrer">
                                Apply Now
                            </a>
                        </div>

                    </div>
                </div>
            );
        });
    };

    return (
        <Section id="openPositions" className="container-xxl mx-auto py-5 px-3">
            <div className=" text-center w-75 mx-auto">
                <h1 className="main-title text-primary">Opportunities With Us</h1>
                {(openingPositionsList.length === 0) ?
                    <>
                        <p className="opacity-75">
                            Sorry, We dont have any openings right now but we are always up to discover new talents,
                            kindly mail us your resume and portfolio link to <a
                            href="mailto:heapworksco@gmail.com">heapworksco@gmail.com</a>
                        </p>
                    </>
                    :
                    <>
                        <p className="opacity-75">
                            If you are unable to find a suitable opening please do not worry. We are always up to
                            discover new talents, kindly mail us your resume and portfolio link to <a
                            href="mailto:heapworksco@gmail.com">heapworksco@gmail.com</a>
                        </p>
                    </>
                }
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 p-3 justify-content-center">
                {renderOpenPositions()}
            </div>
        </Section>
    );
};

export default OpeningPositions;

