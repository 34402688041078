import React from "react";

const Section = ({children, className, id}) => {
    return (
        <section id={id} className={className}>
            <div className="row h-100 w-100 m-0 p-0">
                <div className="col m-0 p-0 my-auto">
                    {children}
                </div>
            </div>
        </section>
    );
};

export default Section;

