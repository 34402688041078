import React, {useEffect} from "react";
import autoScroll from "../Functions/auto-scroll";
import PageTitleCard from "../Components/PageTitleCard";
import FutureRockstar from "./Sections/FutureRockstar";
import OpeningPositions from "./Sections/OpeningPositions";
import ContactUsCard from "../Components/ContactUsCard";


const Career = () => {
    useEffect(() => {
        autoScroll();
    }, []);

    return (
        <div className="career">
            {/*opportunities*/}
            <PageTitleCard
                id="workAtHeapworks"
                title="Work At Heapworks"
                subTitle="It’s More Than Just A Job. We help to build a better future."
            />
            {/*Future Rockstar*/}
            <FutureRockstar/>
            <hr/>
            {/*Opening Positions*/}
            <OpeningPositions/>
            {/*Contact Section*/}
            <ContactUsCard title="Looking For A Unique Position?"/>
        </div>
    );
};

export default Career;

