import React from "react";
import OurTeamImg from "../../assets/Images/undraw_team_collaboration_re_ow29.svg";
import {Link} from "react-router-dom";
// import ButtonPrimaryLarge from "../../Components/Buttons/ButtonPrimaryLarge";

const OurTeam = ({reversed}) => {

    const revers = (
        <div className="row py-5">
            <div className="col d-none d-md-block text-start my-auto">
                <img src={OurTeamImg} alt="Heapworks | Our Team " width="80%"/>
            </div>
            <div className="col text-md-start text-center p-2 p-md-4 my-auto">
                <p className="opacity-75">
                    We are team of top engineers working from across the globe. We help startups and mature
                    organizations with their technology requirements.
                </p>
                <p className="opacity-75">
                    You benefit from the vast experience we have gathered over the years delivering complex projects
                    and cooperating with our customers around the world. Our client relationships are built on
                    mutual trust and safety, and this helps us create your product using a predictable, reliable,
                    quality-driven process shared and mastered over the years.
                </p>
                <Link to={"/career"} className="navbar-brand" reloadDocument={true}>
                    <button className="btn btn-primary">Join Us</button>
                </Link>
            </div>
        </div>
    );

    const normal = (
        <div className="row py-2">
            <div className="col text-md-start text-center p-2 p-md-4 my-auto">
                <p className="opacity-75">
                    We are team of top engineers working from across the glob. We help startups and mature
                    organizations with their technology requirements.
                </p>
                <p className="opacity-75">
                    You benefit from the vast experience we have gathered over the years delivering complex projects
                    and cooperating with our customers around the world. Our client relationships are built on
                    mutual trust and safety, and this helps us create your product using a predictable, reliable,
                    quality-driven process shared and mastered over the years.
                </p>
                <Link to={"/career"} className="navbar-brand" reloadDocument={true}>
                    <button className="btn btn-primary">Join Us</button>
                </Link>
            </div>
            <div className="col d-none d-md-block text-center my-auto">
                <img src={OurTeamImg} alt="Heapworks | Our Team " width="80%"/>
            </div>
        </div>
    );


    return (
        <section id="ourTeam" className="container-xxl mx-auto py-5 px-3">
            <div className="text-center text-md-start">
                <h1 className="text-primary">Our Team</h1>
            </div>
            {(reversed) ? revers : normal}
        </section>
    );
};

export default OurTeam;

