import React from "react";

const PageTitleCard = ({id, title, subTitle}) => {
    return (
        <section id={id} className="h-auto bg-info py-5 slide-up container-fluid">
            <div className="row py-5">
                <div className="col text-center">
                    <h1 className="text-primary fw-bold">{title}</h1>
                    <hr className="w-25 mx-auto"/>
                    <h6 className="w-75 mx-auto opacity-75">{subTitle}</h6>
                </div>
            </div>
        </section>
    );
};

export default PageTitleCard;

