import React, {useEffect} from "react";
import ContactUsCard from "../Components/ContactUsCard";
import autoScroll from "../Functions/auto-scroll";
import PageTitleCard from "../Components/PageTitleCard";
import AboutCompany from "./Sections/AboutCompany";
import OurValues from "./Sections/OurValues";
import OurApproach from "./Sections/OurApproach";
import OurTeam from "./Sections/OurTeam";
import ProfessionalTools from "../Components/ProfessionalTools";

const Company = () => {
    useEffect(() => {
        autoScroll();
    }, []);

    return (
        <div className="about-us">
            {/*Who Are We*/}
            <PageTitleCard
                id={"whoAreWe"}
                title={"Who Are We?"}
                subTitle={"A Software Development company that help grow your business with strong tech foundation."}
            />
            {/*About Us*/}
            <AboutCompany/>
            <hr/>
            {/*Our Team*/}
            <OurTeam/>
            <hr/>
            {/*Our Values*/}
            <OurValues/>
            {/*Professional Tools*/}
            <ProfessionalTools/>
            {/*Our Approach*/}
            <OurApproach/>
            {/*Contact Section*/}
            <ContactUsCard title={"Want To know More About us?"}/>
        </div>
    );
};

export default Company;

