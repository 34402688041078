import React from "react";

// Import icons
import trust from "../../assets/Images/flaticons/trust.png";
import responsibility from "../../assets/Images/flaticons/responsibility.png";
import continuousSelfDev from "../../assets/Images/flaticons/continuousSelfDev.png";
import conversation from "../../assets/Images/flaticons/conversation.png";
import commitment from "../../assets/Images/flaticons/commitment.png";
import flexibility from "../../assets/Images/flaticons/flexibility.png";

const ourValuesList = [
    {
        icon: trust,
        name: "We work for the trust of our customers",
    },
    {
        icon: responsibility,
        name: "We believe that success of the project is our joint responsibility",
    },
    {
        icon: continuousSelfDev,
        name: "We care about our continuous self development",
    },
    {
        icon: conversation,
        name: "We ensure transparent and direct communication",
    },
    {
        icon: commitment,
        name: "We strive to honour our commitments",
    },
    {
        icon: flexibility,
        name: "We understand the need for flexibility in solutions",
    },
];

const OurValues = () => {

    const renderOurValues = () => {
        return ourValuesList.map(({name, icon}, index) => {
            return (
                <div className="col text-center p-2 p-md-4" key={index}>
                    <div className="p-3 border border-1 border-opacity-10 border-primary rounded shadow-sm scale-1">
                        <img src={icon} alt={name}/>
                        <hr className="w-50 mx-auto"/>
                        <p className="my-auto m-0 opacity-75">
                            {name}
                        </p>

                    </div>
                </div>
            );
        });
    };

    return (
        <section id=" ourValues" className=" container-xxl mx-auto py-5 px-3">
            <div className=" text-center">
                <h1 className=" text-primary">Our Values</h1>
                <p className=" w-75 mx-auto opacity-75">
                    Quality is never an accident. It's always the result of intention, sincerity, intelligent planning,
                    and skillful execution.
                </p>
            </div>
            <hr className=" w-25 mx-auto my-4"/>
            <h6 className="text-primary fw-bold mb-4 text-center">
                Our tech culture is shared by our values
            </h6>
            <div className="row row-cols-md-6 row-cols-3">
                {renderOurValues()}
            </div>
        </section>
    )
        ;
};

export default OurValues;

