import React from "react";
import {Link} from "react-router-dom";
import CompanyImage from "../../assets/Images/undraw_building_re_xfcm.svg";

const AboutCompany = () => {
    return (
        <section id="aboutCompany" className="container-xxl mx-auto py-5 px-3 slide-up">
            <div className=" text-center text-md-start">
                <h1 className="text-primary">Company</h1>
            </div>
            <div className="row py-5">
                <div className="col d-none d-md-block text-center my-auto">
                    <img src={CompanyImage} alt="Heapworks | Our Team " width="70%"/>
                </div>
                <div className="col text-md-start text-center p-2 p-md-5 my-auto">
                    <p className="opacity-75">
                        Heapworks was founded in 2021 by two individuals in Hyderabad, India, with a mission to provide
                        one-stop solutions for setting up and scaling startups and companies, large and small, by
                        providing tailored technology services. As a digital and mobile product design and development
                        partner, we work closely with our clients to envision, design, build, and deliver innovative
                        solutions.
                    </p>
                    <p className="opacity-75">
                        We offer web and mobile applications, IT outsourcing, DevOps, maintenance, and more for startups
                        and large corporations.
                    </p>
                    <Link to={"/career"} className="navbar-brand" reloadDocument={true}>
                        <button className="btn btn-primary">Contact US</button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default AboutCompany;

