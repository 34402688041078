export default function autoScroll() {
    const urlHash = window.location.hash;
    if (urlHash.length) {
        const element = document.getElementById(urlHash.substring(1));
        if (element) {
            element.scrollIntoView();
        }
    } else {
        window.scrollTo(0, 0);
    }
}
