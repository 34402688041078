import React from "react";
import ServicesImage from "../../assets/Images/undraw_building_blocks_re_5ahy.svg";

const FutureRockstar = () => {
    return (
        <section id="futureRockstar" className="container-xxl mx-auto py-5 px-3 slide-up">
            <div className="row py-5">
                <div className="col text-md-start text-center p-2 p-md-5 my-auto">
                    <h1 className="text-primary mb-5">
                        We’re Always Looking For Rock Stars
                    </h1>
                    <p className="opacity-75">
                        We can help you advance your career. Expanding your knowledge and developing new skills are
                        extremely valuable. As a team, we foster a culture of self-development and provide many
                        opportunities for skill development. Come join our team and develop your IT skills.
                    </p>
                </div>
                <div className="col d-none d-md-block text-center my-auto">
                    <img src={ServicesImage} alt="Heapworks | Our Team " width="70%"/>
                </div>
            </div>
        </section>
    );
};

export default FutureRockstar;

