import React from "react";
import {Link} from "react-router-dom";
import MainIntroImg from "../../assets/Images/undraw_building_websites_i78t.svg";

const HomeIntroduction = () => {
    return (
        <section id="homeIntro" className="bg-info py-5 slide-up">
            <div className="row row-cols-1 row-cols-md-2 container-xxl mx-auto py-5 px-3">
                <div className="col text-md-start text-center p-2 p-md-5 my-auto">
                    <h1 className="fw-bold">Our Products Are Designed To Help Businesses
                        Succeed</h1>
                    <p className="my-4 opacity-75">One-stop solution to set up and scale your startup. We
                        provide tailored tech services that help establish a strong technology foundation for your
                        startup.</p>
                    <Link to={"/contact"} className="navbar-brand">
                        <button className="btn btn-primary">Free Consultation</button>
                    </Link>
                </div>
                <div className="col p-2 p-md-5 my-auto">
                    <img src={MainIntroImg} alt="Heapworks | Introduction" width="100%"/>
                </div>
            </div>
        </section>
    );
};

export default HomeIntroduction;

