import React, {useEffect} from "react";

// Import Components
import ContactUsCard from "../Components/ContactUsCard";
import HomeIntroduction from "./Sections/HomeIntroduction";
import OurApproach from "./Sections/OurApproach";
import OurTeam from "./Sections/OurTeam";

// Import Custom Function
import autoScroll from "../Functions/auto-scroll";


const HomePage = () => {
    useEffect(() => {
        autoScroll();
    }, []);

    return (
        <div>
            {/*Introduction Section*/}
            <HomeIntroduction/>
            {/*Our Approach Section*/}
            <OurApproach/>
            <hr/>
            {/*Our Team Section*/}
            <OurTeam/>
            {/*Contact Section*/}
            <ContactUsCard/>
        </div>
    );
};

export default HomePage;