import React from "react";
import {Link} from "react-router-dom";
import ServicesImage from "../../assets/Images/undraw_building_blocks_re_5ahy.svg";

const WhatWeDo = () => {
    return (
        <section id="whatWeDo" className="container-xxl mx-auto py-5 px-3 slide-up">
            <div className="row py-5">
                <div className="col d-none d-md-block text-center my-auto">
                    <img src={ServicesImage} alt="Heapworks | Our Team " width="70%"/>
                </div>
                <div className="col text-md-start text-center p-2 p-md-5 my-auto">
                    <h1 className="text-primary mb-5">
                        We Build Awesome Applications To Grow Your Business
                    </h1>
                    <p className="opacity-75">
                        We provide startup and enterprise companies with custom software development services, resulting
                        in exceptional digital experiences at every-stage.
                    </p>
                    <Link to={"/career"} className="navbar-brand" reloadDocument={true}>
                        <button className="btn btn-primary">Contact US</button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default WhatWeDo;

