import React, {useEffect} from "react";
import Section from "../Components/Section";
import GetInTouch from "../assets/Images/undraw_contact_us_re_4qqt.svg";
import autoScroll from "../Functions/auto-scroll";
import PageTitleCard from "../Components/PageTitleCard";
import ContactForm from "../Components/ContactForm";


const ContactUs = () => {
    useEffect(() => {
        autoScroll();
    }, []);

    return (
        <div className="get-in-touch">
            {/*Who Are We*/}
            <PageTitleCard
                id="contactUsForm"
                title="Have A Project In Mind?"
                subTitle="Let's think out loud together. We would love to hear from you. Come, let's get started!"
            />
            <Section className="container-xxl mx-auto py-5 px-3 slide-up">
                <div className="row my-auto">
                    <div className="col col-md-6 d-none d-md-block p-4 my-auto">
                        <img src={GetInTouch} alt="Heapworks | Get in touch" width="100%"/>
                    </div>
                    <div className="col text-md-start text-center p-2 p-md-5 my-auto">
                        <ContactForm/>
                    </div>
                </div>
            </Section>
        </div>
    );
};

export default ContactUs;

