import React from "react";
// import BlobAnimationWithIcon from "../../CustomSVG/BlobAnimationWithIcon";
import ourApproachImage from "../../assets/Images/undraw_scrum_board_re_wk7v.svg";
import Responsive from "../../assets/Images/flaticons/responsive-design.png";
import Dynamic from "../../assets/Images/flaticons/dynamic.png";
import Interface from "../../assets/Images/flaticons/interface.png";
import Fast from "../../assets/Images/flaticons/fast.png";

// Hard Code content
const approachList = [
    {
        name: "Responsive",
        description: "It doesn't matter whether it's a large or small display, our layouts will work",
        icon: Responsive,
    }, {
        name: "Easy To Use",
        description: "We strongly focus on  making UI/UX  intuitive & easy to use",
        icon: Interface,
    }, {
        name: "Dynamic",
        description: "A website doesn't have to be static, we love making it interactive",
        icon: Dynamic,
    }, {
        name: "Fast",
        description: "Lag-free interaction and fast loading times are our top priorities.",
        icon: Fast,
    },
];

const OurApproach = () => {


    const renderApproachList = () => {
        return approachList.map(({name, description, icon}, index) => {
            return (
                <div className="row my-3 mx-auto scale-1" key={index}>
                    <div className="col-3 text-end my-auto">
                        <img src={icon} alt={name}/>
                    </div>
                    <div className="col-9">
                        <h5 className="text-primary">{name}</h5>
                        <p className="fw-light opacity-75">{description}</p>
                    </div>
                </div>
            );
        });
    };


    return (
        <section id="ourApproach" className="container-xxl mx-auto py-5 px-3 slide-up">
            <div className=" text-center text-md-start">
                <h6 className="text-primary">How We Do It ?</h6>
                <h1 className="text-primary">Design-driven Software Innovation</h1>
                <h6 className="opacity-75">Bringing design, technology, and business together.</h6>
            </div>
            <br/>
            <div className="row row-cols-1 row-cols-md-2">
                <div className="col d-none d-md-block  text-start my-auto">
                    <img src={ourApproachImage} alt="Heapworks | Approach" width="80%"/>
                </div>
                <div className="col text-md-start text-center p-2 p-md-4 my-auto">
                    {renderApproachList()}
                </div>
            </div>
        </section>
    );
};

export default OurApproach;

