import React, {useState} from "react";
import allCountryCodes from "../Functions/AllCountryCodes";
import axios from "axios";


const ContactForm = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [emailID, setEmailId] = useState("");
    const [dailCode, setDailCode] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);
    const [registrationStatus, setRegistrationsStatus] = useState(false);

    const onRegistrationSubmit = async (e) => {
        e.preventDefault();

        const userDetails = JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            emailID: emailID,
            dailCode: dailCode,
            phoneNumber: phone,
            userMessage: message,
        });
        setProcessing(true);

        const dbUrl = "https://iaouccjfh4.execute-api.ap-south-1.amazonaws.com/production/send-email";
        await axios.post(dbUrl, userDetails);
        setProcessing(false);

        setEmailId("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setDailCode("");
        setCompanyName("");
        setMessage("");
        setRegistrationsStatus(true);
    };


    const renderContent = () => {
        if (!registrationStatus) {
            return (<>
                    <h1 className="text-primary">Lets Get Connected!!!</h1>
                    <hr/>
                    <form onSubmit={onRegistrationSubmit} className="registration-form">
                        <div className="row">
                            <div className="col-sm-6">
                                {/*First Name*/}
                                <div className="mb-3">
                                    <label htmlFor="firstNameInput" className="form-label h6">First Name</label>
                                    <input type="text" className="form-control" id="firstNameInput"
                                           onChange={e => setFirstName(e.target.value)}
                                           value={firstName} placeholder="" required={true}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                {/*Last Name*/}
                                <div className="mb-3">
                                    <label htmlFor="lastNameInput" className="form-label h6">Last Name</label>
                                    <input type="text" className="form-control" id="lastNameInput"
                                           onChange={e => setLastName(e.target.value)}
                                           value={lastName} placeholder="" required={true}/>
                                </div>
                            </div>
                        </div>

                        {/*Company Name*/}
                        <div className="mb-3">
                            <label htmlFor="companyNameInput" className="form-label h6">Company Name</label>
                            <input type="text" className="form-control" id="lastNameInput"
                                   onChange={e => setCompanyName(e.target.value)}
                                   value={companyName} placeholder="" required={true}/>
                        </div>

                        {/*Email Id*/}
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label h6">Email Id</label>
                            <input type="email" className="form-control" id="emailInput"
                                   onChange={e => setEmailId(e.target.value)} value={emailID} required={true}/>
                        </div>

                        <div className="row">
                            <div className="col-4 col-sm-4 col-md-5 col-lg-4">
                                {/*Dail Code*/}
                                <label htmlFor="dailCodeInput" className="form-label h6">Country Code:</label>
                                <select id="dailCodeInput"
                                        className={`form-select ${!dailCode ? "" : null}`}
                                        value={dailCode}
                                        onChange={e => setDailCode(e.target.value)}>
                                    {!dailCode ? <option>Dail Code</option> : null}
                                    {allCountryCodes.map(({dial_code, name}, index) => {
                                        return (
                                            <option key={index} value={dial_code}>{`${dial_code}`}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-8 col-sm-8 col-md-7 col-lg-8">
                                {/*Phone Number*/}
                                <div className="mb-3">
                                    <label htmlFor="phoneInput" className="form-label h6">Phone Number</label>
                                    <input type="tel" className="form-control" id="lastNameInput"
                                           onChange={e => setPhone(e.target.value)}
                                           value={phone} placeholder=""/>
                                </div>
                            </div>
                        </div>

                        {/*Message*/}
                        <div className="mb-3">
                            <label htmlFor="messageInput" className="form-label h6">Your Message</label>
                            <textarea className="form-control" id="messageInput" rows="3"
                                      onChange={e => setMessage(e.target.value)}
                                      value={message}></textarea>
                        </div>


                        {/*Submit*/}
                        {
                            processing ?
                                <span className="spinner-border text-primary" role="status"/>
                                :
                                <button className="btn btn-primary">Send Message</button>
                        }
                    </form>
                </>

            )
                ;
        } else {
            return (
                <div className="text-center">
                    <h1>Thank You For Getting Connected</h1>
                    <hr/>
                    <h4 className="text-success">Your Message Has been sent Successfully</h4>
                    <p>Our team will get in touch with you in less than 24Hrs</p>
                    <hr/>
                    <button className="btn btn-primary" onClick={() => setRegistrationsStatus(false)}>
                        Home Page
                    </button>
                </div>
            );
        }
    };

    return (
        <div id="contactForm">
            {renderContent()}
        </div>
    );
};

export default ContactForm;
