import React from "react";
import WebMobile from "../../assets/Images/undraw_progressive_app_m-9-ms.svg";
import CustomSoftware from "../../assets/Images/undraw_design_components_9vy6.svg";
import DevOpsCloud from "../../assets/Images/undraw_cloud_hosting_7xb1.svg";
import MVP from "../../assets/Images/undraw_online_connection_6778.svg";
import ProductWorkshops from "../../assets/Images/undraw_environmental_study_re_q4q8.svg";
import SoftwareConsulting from "../../assets/Images/undraw_informed_decision_p2lh.svg";


const ourServices = [
    {
        name: "Web & Mobile Development",
        serviceImage: WebMobile,
        services: [
            "B2B, B2C, Internal Platforms",
            "Backend Development - Java, go, Javascript, NodeJs",
            "Frontend Development - React, Angular",
            "Well-though-out process",
        ],
    },
    {
        name: "Custom Software Development",
        serviceImage: CustomSoftware,
        services: [
            "Digital Transformation",
            "Integration with third-party applications",
            "Enterprise Application",
            "Build Complex Solutions",
        ],
    },
    {
        name: "DevOps & Cloud",
        serviceImage: DevOpsCloud,
        services: [
            "Cloud Migration",
            "Environment Setup",
            "Process Automation",
            "Architecture Building",
        ],
    },
    {
        name: "MVP Development",
        serviceImage: MVP,
        services: [
            "Idea Validation",
            "Prototyping",
            "MVP Development",
        ],
    },
    {
        name: "Product Workshops",
        serviceImage: ProductWorkshops,
        services: [
            "Fully understand your product",
            "Analyze your idea",
            "Speed up project start",
            "Save development time",
            "Get a cost estimate",
            "Get Key Material",
        ],
    },
    {
        name: "Software Consulting",
        serviceImage: SoftwareConsulting,
        services: [
            "Software Procedures Insight",
            "Upgrade Software Products",
            "Improve Sales & Operational Efficiency",
            "Adapting Cutting-Edge Technology",
        ],
    },
];


const WhatWeOffer = () => {
    const renderServices = () => {

        const renderSubServices = (services) => {
            return services.map((subServiceName, index) => {
                return (
                    <p key={index} className="px-4">{subServiceName}</p>
                );
            });
        };

        return ourServices.map(({name, serviceImage, services}, index) => {

            const normal = (
                <>
                    <div className="col text-md-start text-center p-2 p-md-4 my-auto w-50">
                        <h3 className="text-primary">{name}</h3>
                        <hr className="w-50"/>
                        {renderSubServices(services)}
                    </div>
                    <div className="col d-none d-md-block text-center my-auto">
                        <img src={serviceImage} alt="Heapworks | Our Team " width="60%"/>
                    </div>
                </>
            );

            const reverses = (
                <>
                    <div className="col d-none d-md-block text-center my-auto">
                        <img src={serviceImage} alt={`Heapworks |  ${name}`} width="60%"/>
                    </div>
                    <div className="col text-md-start text-center p-2 p-md-4 my-auto w-50">
                        <h3 className="text-primary">{name}</h3>
                        <hr className="w-50"/>
                        {renderSubServices(services)}
                    </div>
                </>
            );

            return (
                <>
                    <div key={index} className="row py-4 my-4 bg-info rounded-4">
                        {(index % 2 === 0) ? normal : reverses}
                    </div>
                </>
            );
        });
    };

    return (
        <section id="whatWeOffer" className="container-xxl mx-auto py-5 px-3">
            <div className=" text-center">
                <h1 className="text-primary">Our Services</h1>
            </div>
            {renderServices()}
        </section>
    );
};

export default WhatWeOffer;

