import React from "react";
import {Route, Routes} from "react-router-dom";

// Pages:
import HomePage from "./Pages/HomePage";
import Company from "./Pages/Company";
import Services from "./Pages/Services";
import Career from "./Pages/Career";
import ContactUs from "./Pages/ContactUs";

// Components
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";


const App = () => {
    return (
        <>
            <NavBar/>
            <div className="App">
                <Routes>
                    <Route path={"/"} element={<HomePage/>}/>
                    <Route path={"/company"} element={<Company/>}/>
                    <Route path={"/services"} element={<Services/>}/>
                    <Route path={"/career"} element={<Career/>}/>
                    <Route path={"/contact"} element={<ContactUs/>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
};

export default App;
