import React from "react";

// Images
// import LogoLightFooter from "../assets/Images/OldImages/LogoLightFooter.png";
import LogoLightFooter from "../assets/Images/Logo-White-x516.png";

// Icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";

import {faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";

const companyDetails = {
    name: "Heapworks (OPC) Private Limited",
    address: "7-97/2, Kuntloor, Hyderabad, India - 501505",
    email: "thedude@heapworks.in",
    mobile: "+91 9885969290",
    socialMedia: [
        {
            name: "linkedIn",
            faIcon: faLinkedin,
            url: "https://www.linkedin.com/company/heapworks/",
        },
    ],
};

const websiteSubPages = [
    {
        pageName: "Company",
        pageUrl: "/company",
        section: [
            {
                name: "Our Team",
                sectionID: "ourTeam",
            },
            {
                name: "Our Values",
                sectionID: "ourValues",
            },
            {
                name: "Our Approach",
                sectionID: "ourApproach",
            },

        ],
    },
    {
        pageName: "Services",
        pageUrl: "/services",
        section: [
            {
                name: "What We Do",
                sectionID: "whatWeDo",
            },
            {
                name: "What We Offer",
                sectionID: "whatWeOffer",
            },
            {
                name: "Our Practices",
                sectionID: "ourPractices",
            },
        ],
    },
    {
        pageName: "Career",
        pageUrl: "/career",
        section: [
            {
                name: "Work At HeapWorks",
                sectionID: "workAtHeapworks",
            },
            {
                name: "Future Rockstar",
                sectionID: "futureRockstar",
            },
            {
                name: "Open Positions",
                sectionID: "openPositions",
            },
        ],
    },
];


const Footer = () => {

    const renderFooterLinks = () => {
        return websiteSubPages.map(({pageName, pageUrl, section}, index) => {
            return (
                <div key={index} className="col-md-2 d-none d-md-block pt-md-4 pt-lg-5">
                    <Link className="text-decoration-none" reloadDocument={true} to={pageUrl}>
                        <h6 className="text-light fw-bold">
                            {pageName}
                        </h6>
                    </Link>
                    {
                        section.map(({name, sectionID}, index2) => {
                            return (
                                <Link key={index2} className="text-decoration-none" reloadDocument={true}
                                      to={pageUrl + "#" + sectionID}>
                                    <p className="text-light small m-0">{name}</p>
                                </Link>
                            );
                        })
                    }
                </div>
            );
        });
    };

    const renderSocialMediaIcons = () => {
        return companyDetails.socialMedia.map(({faIcon, url}, index) => {
            return (
                <a href={url} key={index} target="_blank" rel="noreferrer" className="text-light pe-2">
                    <FontAwesomeIcon className="h3 m-0" icon={faIcon}/>
                </a>
            );
        });
    };


    const renderContactInfo = () => {
        return (
            <div className="col col-md-4">
                <div className="mb-4">
                    <Link to={"/"} reloadDocument={true}>
                        <img src={LogoLightFooter} alt="Heapworks Light Logo" height="50"/>
                    </Link>
                </div>
                <div className="mb-4">
                    <small>
                        <FontAwesomeIcon icon={faEnvelope} className="me-3"/> {companyDetails.email}
                    </small>
                    <br/>
                    <small>
                        <FontAwesomeIcon icon={faPhone} className="me-3"/> {companyDetails.mobile}
                    </small>
                </div>
                <div className="row">
                    <div className="col-4 col-sm-3 col-md-6 col-lg-4">
                        <small className="my-auto me-3">Find Us On </small>
                    </div>
                    <div className="col-8 col-sm-9 col-md-6  col-lg-8">
                        {renderSocialMediaIcons()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <footer className="text-light m-0 p-0 bg-dark">
            <div className="container-xxl p-1">
                <div className="row justify-content-between p-3 py-md-3 px-md-2 opacity-75 pb-3">
                    {renderContactInfo()}
                    {renderFooterLinks()}
                </div>
            </div>
            <div className="bg-black">
                <div className="container-xxl p-3">
                    <p className="small mt-3 fw-lighter opacity-75">
                        © {new Date().getFullYear()} Heapworks Pvt Ltd. All rights reserved.
                    </p>
                    <p className="small mt-3 fw-lighter opacity-75 d-none d-md-block">
                        Heapworks is a private company, with its registered office in Hyderabad at Plot No. 49 & 50,
                        Kuntloor Colony, Rangareddy, Telangana, India - 501505, incorporated in the Register of
                        Hyderabad. This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of
                        Service apply.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

