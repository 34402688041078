import React, {useEffect} from "react";
import ContactUsCard from "../Components/ContactUsCard";

import autoScroll from "../Functions/auto-scroll";
import PageTitleCard from "../Components/PageTitleCard";
import WhatWeDo from "./Sections/WhatWeDo";
import WhatWeOffer from "./Sections/WhatWeOffer";

const Services = () => {

    useEffect(() => {
        autoScroll();
    }, []);

    return (
        <div className="services">
            {/*What We Do*/}
            <PageTitleCard
                id="servicesCard"
                title="What We Offer?"
                subTitle="We Provide one-stop solution to set up and scale your business to the next level."
            />
            {/*What We Do?*/}
            <WhatWeDo/>
            <hr/>
            {/*What-We-Offer*/}
            <WhatWeOffer/>
            {/*<hr/>*/}
            {/*Our Practices*/}
            {/*<OurPractices/>*/}
            {/*Contact Section*/}
            <ContactUsCard title={"Do You Have Any Specific Needs?"}/>
        </div>
    );
};

export default Services;

