import React from "react";
import { Link } from "react-router-dom";

import MainIntroImg from "../assets/Images/Shape.svg";
import GetInTouch from "../assets/Images/undraw_contact_us.svg";

const ContactUsCard = ({ title, subTitle }) => {

    return (
        <section className="Contact-Us h-auto">
            <img src={MainIntroImg} alt="Heapworks | Introduction" width="100%" />
            <div className="bg-primary text-light px-2 py-5 p-md-5 mx-auto w-auto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 d-flex align-items-center">
                            <div>
                                <h3 className="text-light">{(title) ? title : "Got A Project Or A Partnership In Mind?"}</h3>
                                <p>{(subTitle) ? subTitle : "Together, let's create tomorrow's design technology"}</p>
                                <Link reloadDocument={true} to={"/contact"}>
                                    <button className="btn btn-light btn-lg">Get In Touch</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 d-none d-md-block text-center d-grid mx-auto">
                            <img src={GetInTouch} alt="Heapworks | Get in touch" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ContactUsCard;

