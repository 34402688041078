import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

// Images
import LogoNavBar from "../assets/Images/logo-original-50px.png";

// Links Details
const pagesDetails = [
    {
        link: "/company",
        name: "Company",
        button: false,
    },
    {
        link: "/services",
        name: "Services",
        button: false,
    },
    {
        link: "/career",
        name: "Career",
        button: false,
    },
    {
        link: "/contact",
        name: "Free Consultation",
        button: true,
    },
];


const NavBar = () => {

    const [navShadow, setNavShadow] = useState(false);

    const getActiveSection = () => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 200) {
                setNavShadow(true);
            } else {
                setNavShadow(false);
            }
        });
    };

    useEffect(() => {
        getActiveSection();
    });

    const generateNavItems = () => {
        return pagesDetails.map(({ name, link, button }, index) => {
            return (
                <li key={index} className="nav-item my-auto">
                    <NavLink to={link} end={true} className={`nav-link ${(button) ? "nav-btn" : "nav-text"}`}>
                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            {button ? <button className="btn btn-primary">{name}</button> : name}
                        </div>
                    </NavLink>
                </li>
            );
        });
    };

    return (
        <header className="sticky-top bg-white">
            <nav className={`navbar navbar-expand-md ${(navShadow) ? "shadow" : ""}`}>
                <div className="container-xxl">
                    <Link to={"/"} className="navbar-brand" reloadDocument={true}>
                        <img className="d-inline-block" src={LogoNavBar} alt="Heapworks Logo"
                            height="50px" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarToggler" aria-controls="navbarToggler"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ms-auto align-items-end">
                            {generateNavItems()}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default NavBar;

