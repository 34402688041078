import React from "react";

// icons

import Slack from "../assets/Images/CustomIcons/slack.png";
import JIRA from "../assets/Images/CustomIcons/jira.png";
import Figma from "../assets/Images/CustomIcons/figma.png";
import GoogleDrive from "../assets/Images/CustomIcons/google-drive.png";
import GoogleMeet from "../assets/Images/CustomIcons/videocall.png";
import Github from "../assets/Images/CustomIcons/github.png";
import Miro from "../assets/Images/CustomIcons/miro.png";

const toolsList = [
    {
        name: "Slack",
        icon: Slack,
    },
    {
        name: "JIRA",
        icon: JIRA,
    },
    {
        name: "Figma",
        icon: Figma,
    },
    {
        name: "Google Drive",
        icon: GoogleDrive,
    },
    {
        name: "Google Meet",
        icon: GoogleMeet,
    },
    {
        name: "Github",
        icon: Github,
    },
    {
        name: "Miro",
        icon: Miro,
    },
];

const ProfessionalTools = () => {

    const renderProfessionalTools = () => {
        return toolsList.map(({name, icon}, index) => {
            return (
                <div key={index} className="col text-center mx-3 p-3 scale-1">
                    <img src={icon} alt={name}/>
                    <hr className="w-25 mx-auto border-light"/>
                    <p className="text-light mb-0">{name}</p>
                </div>
            );
        });

    };

    return (
        <section className="h-auto bg-primary py-5">
            <div className="container-xxl">
                <h3 className="text-light text-center mb-4">Professional Tools</h3>
                <div className="row">
                    {renderProfessionalTools()}
                </div>
            </div>
        </section>

    );
};

export default ProfessionalTools;